import ApiService from '@/api/apiService'
import type { AxiosResponse } from 'axios'

class ActiveParsingApi {
  static readonly url = '/scrapper/active-parsing'

  public static getItems(): Promise<AxiosResponse> {
    return ApiService.get(this.url)
  }
}

export default ActiveParsingApi
