import { defineStore } from 'pinia';
import { ParsingTaskModel, ShortParsingTaskModel } from "@/models/parsing-task";
import { ElNotification } from "element-plus";
import { useAuthStore } from '.'
import ActiveParsingApi from "@/api/active-parsing";

export const useTasksStore = defineStore({
  id: 'tasks',

  state: () => ({
    currentTasks: [] as ShortParsingTaskModel[],
    currentBlacklistTasks: [] as ShortParsingTaskModel[],
    taskCount: null as number | null,
    taskBlacklistCount: null as number | null,
  }),

  getters: {
    getTaskCount(): number {
      return this.taskCount || 0;
    },
    getTaskBlacklistCount(): number {
      return this.taskBlacklistCount || 0;
    },
  },

  actions: {
    async getParseWords() {
      const authStore = useAuthStore()
      if (!authStore.isLogin) return

      try {
        const { data } = await ActiveParsingApi.getItems()

        const tasks: ShortParsingTaskModel[] = data.parser_task.map((e: ParsingTaskModel) => ({
          id: e.id,
          title: e.title,
          status: e.status
        }))

        const blacklistTasks: ShortParsingTaskModel[] = data.black_list_item.map((e: ParsingTaskModel) => ({
          id: e.id,
          title: e.title,
          status: e.status
        }))

        this.taskCount = data.active_parser_tasks_count || 0
        this.taskBlacklistCount = data.active_black_list_items_count || 0

        await this.updateTasks(tasks, this.currentTasks);
        this.currentTasks = tasks
        await this.updateTasks(blacklistTasks, this.currentBlacklistTasks);
        this.currentBlacklistTasks = blacklistTasks

      } catch (e: any) {
        console.warn(e)
      }
    },

    // update tasks
    async updateTasks(tasks: ShortParsingTaskModel[], currentTasks: ShortParsingTaskModel[]) {
      const startedTasks = tasks.filter(task => {
        return currentTasks.some(cTask => cTask.id === task.id
          && task.status === 'started'
          && cTask.status !== 'started'
        )
      })

      startedTasks.forEach(task => {
        ElNotification.info({
          type: 'info',
          title: 'Task Started',
          message: `Start scraping with <br> <span style="color: #6CADDF">${task.title}</span>`,
          dangerouslyUseHTMLString: true,
        });
      });

      const finishedTasks = tasks.filter(task => {
        return currentTasks.some(cTask => cTask.id === task.id
          && task.status !== 'started'
          && cTask.status === 'started'
        )
      })

      finishedTasks.forEach(task => {
        ElNotification.success({
          type: 'success',
          title: 'Task Completed',
          message: `Completed scraping with <br> <span style="color: #67c23a">${task.title}</span>`,
          dangerouslyUseHTMLString: true,
        });
      });
    },
  },
});
