<script setup lang="ts">
  import { navItems, bottomNavItems } from "@/layouts/sidebar/sidebar-items";
  import SvgIcon from '@/components/SvgIcon.vue'
  import { useRoute, useRouter } from 'vue-router';
  import { useAuthStore } from "@/store";

  const auth = useAuthStore();
  const route = useRoute();
  const router = useRouter();

  function subIsActive(link: string): boolean {
    return route.path === link;
  }
</script>

<template>
  <nav class="nav">
    <ul class="nav__list custom-scrollbar">
      <li
        v-for="item in navItems.filter(e => e.display !== false)"
        :key="item.title"
        class="nav__list-item"
      >
        <router-link
          v-if="!item.disabled"
          :to="item.link"
          :class="{ 'is-active': subIsActive(item.link) }"
          class="nav__link"
        >
          <span class='nav__icon'>
            <svg-icon width="20" height="20" :icon="item.icon" />
          </span>
          <span>{{ item.title }}</span>
        </router-link>

        <div v-else class="nav__link is-disabled">
          <span class='nav__icon'>
            <svg-icon width="20" height="20" :icon="item.icon" />
          </span>
          <span>{{ item.title }}</span>
        </div>
      </li>

      <li class="nav__separator"></li>

      <!-- bottom links -->
      <li
        v-for="item in bottomNavItems"
        :key="item.title"
        class="nav__list-item"
      >
        <router-link
          v-if="!item.disabled"
          :to="item.link"
          :class="{ 'is-active': subIsActive(item.link) }"
          class="nav__link"
        >
          <span class='nav__icon'>
            <svg-icon width="20" height="20" :icon="item.icon" />
          </span>
          <span>{{ item.title }}</span>
        </router-link>
      </li>

      <li class="nav__list-item">
        <button @click="auth.logout(router)" class="nav__link">
          <span class='nav__icon'>
            <svg-icon width="20" height="20" icon="logout2" />
          </span>
          <span>Log Out</span>
        </button>
      </li>
    </ul>
  </nav>
</template>

<style scoped lang="scss">
  .nav {
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 250px;
    height: 100vh;
    flex: 0 0 250px;
    padding: 90px 0 40px;
    top: 0;
    overflow-y: auto;
    background-color: var(--color-alabaster);
    box-shadow: var(--side-bar-border);

    &__separator {
      margin: auto 0;
      height: 0;
    }

    &__list {
      display: flex;
      flex-direction: column;
      height: 100vh;
      width: 100%;
      margin: 0;
      padding: 0;
    }

    &__list-item {
      display: block;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    &__link {
      position: relative;
      display: flex;
      align-items: center;
      padding: 6px 22px;
      color: var(--nav-link-color);
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      text-decoration: none;

      // for button
      width: 100%;
      background-color: transparent;
      border: 0;
      cursor: pointer;

      &:hover {
        color: var(--nav-link-color-active);
      }

      &.is-active {
        color: var(--color-red-ribbon);

        &::before {
          content: "";
          position: absolute;
          display: block;
          width: 3px;
          height: 100%;
          left: 0;
          top: 0;
          background-color: var(--color-red-ribbon);
          border-radius: 0 3px 3px 0;
        }
      }

      &.is-disabled {
        color: var(--nav-link-color-disabled);
        cursor: not-allowed;
      }
    }

    &__icon {
      flex: 0 0 20px;
      height: 20px;
      margin-right: 20px;
    }
  }
</style>
