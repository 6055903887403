import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/scss/main.scss'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createPinia } from 'pinia'
import ApiService from "@/api/apiService";

const app = createApp(App)
const pinia = createPinia()

app
  .use(router)
  .use(ElementPlus)
  .use(pinia)
  .use(VueAxios, axios)

ApiService.init(app)

app.mount('#app')
