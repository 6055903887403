<script setup lang="ts">
import { watchEffect, ref } from "vue";
import SideBar from '@/layouts/sidebar/SideBar.vue'
import CustomHeader from "@/layouts/custom-header/CustomHeader.vue";
import { useAuthStore } from '@/store';
import { RouteLocationNormalizedLoaded, RouteRecordName, useRoute} from 'vue-router';

const auth = useAuthStore();
const route = useRoute();
const currentPageName = ref(getPageName(route));

function getPageName(route: RouteLocationNormalizedLoaded): RouteRecordName {
  return route.name!;
}

watchEffect(() => {
  currentPageName.value = getPageName(route);
});
</script>

<template>
  <CustomHeader />
  <div class="wrapper" :key="currentPageName">
    <SideBar v-if="auth.isLogin" />
    <router-view/>
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

#app {
  font-family: 'Poppins', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

button {
  font-family: 'Poppins', sans-serif;
}
</style>
