import ApiService from '@/api/apiService'
import type { AxiosResponse } from 'axios'

class CoreAutoCategoriesApi {
  static readonly url = '/core/categories/auto-category'

  public static getAutoCategory(): Promise<AxiosResponse> {
    return ApiService.get(`${this.url}`)
  }

  public static setAutoCategory(value: boolean): Promise<AxiosResponse> {
    const params = { 'auto_category': (value ? 1 : 0) }
    return ApiService.put(`${this.url}`, params)
  }
}

export default CoreAutoCategoriesApi
