import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: to => {
      return { path: '/new-search' }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/LoginPage.vue'),
  },
  {
    path: '/password/forgot',
    name: 'password-forgot',
    component: () => import('@/views/auth/PasswordForgotPage.vue'),
  },
  {
    path: '/password/reset',
    name: 'password-reset',
    component: () => import('@/views/auth/PasswordResetPage.vue'),
  },
  {
    path: '/new-search',
    name: 'new-search',
    component: () => import('@/views/common/new-search/NewSearch.vue'),
    props: () => ({ mode: 'new-search' })
  },
  {
    path: '/black-list-search',
    name: 'black-list-search',
    component: () => import('@/views/common/new-search/NewSearch.vue'),
    props: () => ({ mode: 'blacklist' })
  },
  {
    path: '/hidden-items',
    name: 'hidden-items',
    component: () => import('@/views/common/hidden-items/HiddenItems.vue'),
  },
  {
    path: '/client-reports',
    name: 'client-reports',
    component: () => import('@/views/common/ClientReports.vue'),
  },
  {
    path: '/reported-items',
    name: 'reported-items',
    component: () => import('@/views/common/reported-items/ReportedItems.vue'),
  },
  {
    path: '/unreported-items',
    name: 'unreported-items',
    component: () => import('@/views/common/unreported-items/UnreportedItems.vue'),
  },
  {
    path: '/unsorted-items',
    name: 'unsorted-items',
    component: () => import('@/views/common/unsorted-items/UnsortedItems.vue'),
  },
  {
    path: '/archives-items',
    name: 'archives-items',
    component: () => import('@/views/common/archives/ArchivesItems.vue'),
  },
  {
    path: '/whitelist',
    name: 'whitelist',
    component: () => import('@/views/common/white-list/WhiteList.vue'),
  },
  {
    path: '/blacklist',
    name: 'blacklist',
    component: () => import('@/views/common/black-list/BlackList.vue'),
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('@/views/common/statistics/StatisticsPage.vue'),
  },
  {
    path: '/client-templates/:id',
    name: 'client-template',
    component: () => import('@/views/common/client-templates/ClientTemplate.vue'),
  },
  {
    path: '/client-templates',
    name: 'client-templates',
    component: () => import('@/views/common/client-templates/ClientTemplates.vue'),
  },
  {
    path: '/keywords',
    name: 'keywords',
    component: () => import('@/views/common/keyword/KeywordsPage.vue'),
  },
  {
    path: '/ignored-keywords',
    name: 'ignored-keywords',
    component: () => import('@/views/common/ignored-keywords/IgnoredKeywordsPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/common/NotFoundPage.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = useAuthStore();
  const notAuthRoutes = ['/login', '/password/reset', '/password/forgot']

  if (!auth.isLogin && !notAuthRoutes.includes(to.path)) {
    next('/login');
  } else if (!auth.isLogin && notAuthRoutes.includes(to.path)) {
    next();
  } else if (auth.isLogin && notAuthRoutes.includes(to.path)) {
    next('/new-search');
  } else {
    next();
  }
});

export default router
