import { ElNotification } from 'element-plus'

export function showErrorMessage(error: any, defaultMessage: string, title = 'Error'): void {
  const message = error?.response?.data?.message || defaultMessage || ''
  ElNotification.error({
    title,
    message,
    type: 'danger',
  });
}

export function showSuccessMessage(message: string, title = 'Done'): void {
  ElNotification.success({
    title,
    message,
    type: 'success',
  });
}

export function showWarningMessage(message: string, title = 'Warning'): void {
  ElNotification.warning({
    title,
    message,
    type: 'warning',
  });
}
