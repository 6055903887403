import { defineStore  } from 'pinia'
import { Router } from 'vue-router';
import AuthApi from "@/api/auth";

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    isAuthenticated: localStorage.getItem('id_token') !== null,
    token: localStorage.getItem('id_token') || '',
    email: localStorage.getItem('user_email') || '',
    name: localStorage.getItem('user_name') || '',
    avatar: localStorage.getItem('user_avatar') || '',
    company: localStorage.getItem('user_company') || '',
  }),

  getters: {
    isLogin: (state) => state.isAuthenticated,
    getName: (state) => state.name,
    getAvatar: (state) => state.avatar,
    getCompany: (state) => state.company,
  },

  actions: {
    login(token: string, email: string, name: string, avatar: string, company: string) {
      this.isAuthenticated = true;
      this.token = token;
      this.email = email;
      this.name = name;
      this.avatar = avatar;
      this.company = company;
      localStorage.setItem('id_token', token);
      localStorage.setItem('user_email', email);
      localStorage.setItem('user_name', name);
      localStorage.setItem('user_avatar', avatar);
      localStorage.setItem('user_company', company);
    },

    async logout(router: Router) {
      try {
        await AuthApi.logout()

        this.isAuthenticated = false;
        this.token = '';
        this.email = '';
        this.name = '';
        this.avatar = '';
        this.company = '';
        localStorage.removeItem('id_token');
        localStorage.removeItem('user_email');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_avatar');
        localStorage.removeItem('user_company');

        await router.push('/login');

      } catch (e: any) {
        console.log(e)
      }
    },
  },
});
