import ApiService from '@/api/apiService'
import type { AxiosResponse } from 'axios'

class AuthApi {
  static readonly url = '/auth'

  public static login(params: object): Promise<AxiosResponse> {
    return ApiService.post(`${this.url}/login`, params)
  }

  public static logout(): Promise<AxiosResponse> {
    return ApiService.post(`${this.url}/logout`, {})
  }

  public static forgot(params: object): Promise<AxiosResponse> {
    return ApiService.post(`${this.url}/password/forgot`, params)
  }

  public static reset(params: object): Promise<AxiosResponse> {
    return ApiService.post(`${this.url}/password/reset`, params)
  }
}

export default AuthApi
